




































import { Component, Vue } from 'vue-property-decorator'
import { alertController } from './alert-controller'
@Component
export default class Alert extends Vue {
  controller = alertController
}
